<template>
    <div class="flex w-56 flex-col items-center text-center text-plu-black">
        <div
            class="mb-6 flex h-16 w-16 items-center justify-center rounded-2xl bg-plu-blue shadow-plu-icon-shadow md:h-24 md:w-24 md:rounded-large"
        >
            <component :is="icon"></component>
            <!--            <img-->
            <!--                alt="icon"-->
            <!--                class="h-8 w-8 object-contain object-center md:h-12 md:w-12"-->
            <!--                :src="props.iconSrc"-->
            <!--            />-->
        </div>
        <paragraph class="mb-1 font-bold">{{ props.title }}</paragraph>
        <paragraph>{{ props.subtitle }}</paragraph>
    </div>
</template>
<script setup>
import Paragraph from '@/Components/texts/Paragraph.vue'

const props = defineProps({
    icon: {
        type: Object,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
        required: true,
    },
})
</script>
