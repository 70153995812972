<template>
    <svg
        width="31"
        height="32"
        viewBox="0 0 31 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.4975 0.640137C19.5706 0.640137 23.4769 2.25816 26.357 5.13825C29.2371 8.01835 30.8551 11.9246 30.8551 15.9977C30.855 16.4156 30.7016 16.819 30.4238 17.1313C30.1461 17.4437 29.7634 17.6432 29.3483 17.6921L29.1487 17.7041H17.2039V26.236C17.2044 26.6709 17.371 27.0893 17.6695 27.4055C17.9681 27.7218 18.3762 27.9121 18.8103 27.9376C19.2445 27.9631 19.672 27.8218 20.0056 27.5427C20.3391 27.2635 20.5534 26.8676 20.6048 26.4357L20.6167 26.236C20.6167 25.7835 20.7965 25.3494 21.1165 25.0294C21.4365 24.7094 21.8706 24.5296 22.3231 24.5296C22.7757 24.5296 23.2097 24.7094 23.5297 25.0294C23.8497 25.3494 24.0295 25.7835 24.0295 26.236C24.0308 27.5686 23.5124 28.8491 22.5846 29.8055C21.6567 30.7619 20.3925 31.3188 19.0606 31.3579C17.7286 31.397 16.4339 30.9152 15.4515 30.0149C14.4692 29.1146 13.8766 27.8667 13.7997 26.5364L13.7912 26.236V17.7041H1.84641C1.42845 17.704 1.02506 17.5506 0.712728 17.2728C0.400398 16.9951 0.20086 16.6124 0.151959 16.1973L0.140015 15.9977C0.140015 11.9246 1.75804 8.01835 4.63813 5.13825C7.51823 2.25816 11.4245 0.640137 15.4975 0.640137Z"
            fill="white"
        />
    </svg>
</template>
<script setup></script>
