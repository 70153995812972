<template>
    <svg
        width="31"
        height="32"
        viewBox="0 0 31 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.97998 9.59998V7.03998C3.97998 6.36103 4.24969 5.70988 4.72979 5.22979C5.20988 4.74969 5.86103 4.47998 6.53998 4.47998H9.09998M21.9 4.47998H24.46C25.1389 4.47998 25.7901 4.74969 26.2702 5.22979C26.7503 5.70988 27.02 6.36103 27.02 7.03998V9.59998M27.02 22.4V24.96C27.02 25.6389 26.7503 26.2901 26.2702 26.7702C25.7901 27.2503 25.1389 27.52 24.46 27.52H21.9M9.09998 27.52H6.53998C5.86103 27.52 5.20988 27.2503 4.72979 26.7702C4.24969 26.2901 3.97998 25.6389 3.97998 24.96V22.4"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="9.09998"
            y="9.6001"
            width="12.8"
            height="12.8"
            rx="4"
            fill="white"
        />
    </svg>
</template>
