<template>
    <div class="border-t border-y-plu-black py-6 text-plu-black">
        <div
            role="button"
            class="flex flex-row items-start justify-between gap-x-4"
            @click="toggleOpen"
        >
            <FaqQuestionTitle role="button"
                >Article {{ questionNumber }}. {{ question }}
            </FaqQuestionTitle>
            <faq-arrow
                :class="isOpen ? 'rotate-180' : 'rotate-0'"
                class="delay-50 h-8 w-8 shrink-0 transition-transform duration-500"
            ></faq-arrow>
        </div>
        <div
            class="overflow-hidden transition-all duration-500"
            :class="isOpen ? 'max-h-96' : 'max-h-0'"
        >
            <p :key="answer" v-for="(answer, index) in answers" class="mt-4">
                {{ questionNumber }}.{{ index + 1 }} {{ answer }}
            </p>
        </div>
    </div>
</template>
<script setup>
import FaqQuestionTitle from '@/Components/texts/FaqQuestionTitle.vue'
import FaqArrow from '@/Components/images/icons/FaqArrow.vue'
import { ref } from 'vue'

const isOpen = ref(false)

const toggleOpen = () => {
    isOpen.value = !isOpen.value
}

defineProps({
    question: String,
    answers: Array,
    questionNumber: Number,
})
</script>
