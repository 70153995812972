<template>
    <div class="flex flex-col">
        <paragraph class="mb-1">
            <span class="mb-2 text-xl"
                >{{ props.title }} {{ props.subtitle ? '-' : '' }}
            </span>
            {{ props.subtitle }}
        </paragraph>
        <paragraph
            class="mb-1 text-plu-text-gray"
            :key="line"
            v-for="line in lines"
        >
            {{ line }}</paragraph
        >
        <primary-button :href="props.href" class="mt-2 w-max">
            {{ props.buttonText }}
        </primary-button>
    </div>
</template>
<script setup>
import PrimaryButton from '@/Components/buttons/PrimaryButton.vue'
import Paragraph from '@/Components/texts/Paragraph.vue'

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    subtitle: {
        default: '',
        type: String,
    },
    lines: {
        type: Array,
        required: true,
    },
    href: {
        type: String,
        required: true,
    },
    buttonText: {
        type: String,
        required: true,
    },
})
</script>
