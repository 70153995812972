<template>
    <div ref="floatComponent" class="float-animation">
        <slot></slot>
    </div>
</template>
<script setup></script>
<style scoped>
@keyframes float {
    0% {
        transform: translate3d(0px, 5px, 0);
    }
    50% {
        transform: translate3d(0, -5px, 0px);
    }
    100% {
        transform: translate3d(0px, 5px, 0);
    }
}

.float-animation {
    animation: float 3s ease-in-out infinite;
}
</style>
