<template>
    <div ref="scrollWrapper">
        <slot></slot>
    </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref } from 'vue'

const scrollWrapper = ref(null)

const props = defineProps({
    startTranslateX: { type: Number, default: 0 },
    endTranslateX: { type: Number, default: 0 },
    startTranslateY: { type: Number, default: 0 },
    endTranslateY: { type: Number, default: 0 },
    startRotate: { type: Number, default: 0 },
    endRotate: { type: Number, default: 0 },
    startScale: { type: Number, default: 1 },
    endScale: { type: Number, default: 2 },
    startBlur: { type: Number, default: 0 },
    endBlur: { type: Number, default: 0 },
})

onMounted(() => {
    window.addEventListener('scroll', animate)
    setTimeout(() => {
        animate()
    }, 100)
})

onUnmounted(() => {
    window.removeEventListener('scroll', animate)
})

function animate() {
    const rect = scrollWrapper.value.getBoundingClientRect()
    if (rect.top > window.innerHeight) {
        setToBasicValues()
    } else if (rect.top < 0) {
        setToEndValues()
    } else {
        const PercentageOnScroll =
            (window.innerHeight - rect.top) / window.innerHeight
        const translateX =
            props.startTranslateX +
            PercentageOnScroll * (props.endTranslateX - props.startTranslateX)

        const translateY =
            props.startTranslateY +
            PercentageOnScroll * (props.endTranslateY - props.startTranslateY)
        const rotate =
            props.startRotate +
            PercentageOnScroll * (props.endRotate - props.startRotate)

        const scale =
            props.startScale +
            PercentageOnScroll * (props.endScale - props.startScale)

        const blur =
            props.startBlur +
            PercentageOnScroll * (props.endBlur - props.startBlur)

        scrollWrapper.value.style.transform = `translateX(${translateX}px) translateY(${translateY}px) rotate(${rotate}deg) scale(${scale})`
        scrollWrapper.value.style.filter = `blur(${blur}px)`
    }
}

function setToBasicValues() {
    scrollWrapper.value.style.transform = `translateX(${props.startTranslateX}px)
    translateY(${props.startTranslateY}px)
    rotate(${props.startRotate}deg)
    scale(${props.startScale})`
    scrollWrapper.value.style.filter = `blur(${props.startBlur}px)`
}

function setToEndValues() {
    scrollWrapper.value.style.transform = `translateX(${props.endTranslateX}px)
    translateY(${props.endTranslateY}px)
    rotate(${props.endRotate}deg)
    scale(${props.endScale})`
    scrollWrapper.value.style.filter = `blur(${props.endBlur}px)`
}
</script>
