<template>
    <h3 class="font-BwGRadual text-3xl font-medium text-plu-black md:text-5xl">
        <slot></slot>
    </h3>
</template>
<style scoped>
:deep(span) {
    @apply text-plu-blue;
}
</style>
<script setup></script>
