<template>
    <section
        class="relative bg-plu-black py-24"
        id="participating-locations-block"
    >
        <inner-constraint class="relative">
            <Float class="absolute -top-36 right-0 md:right-12">
                <scroll-animator
                    :start-translate-x="0"
                    :end-translate-x="-150"
                    :end-scale="1.1"
                    :end-rotate="5"
                    :start-rotate="-5"
                >
                    <Image
                        class="w-44 md:w-80"
                        alt="Clouds"
                        :src="Clouds"
                    ></Image>
                </scroll-animator>
            </Float>

            <heading4 class="mb-6">Where you can find us</heading4>
            <heading3 class="text-white">Participating locations</heading3>
            <div
                class="mt-20 flex flex-col-reverse flex-nowrap md:flex-row md:gap-x-8 lg:gap-x-12"
            >
                <div
                    class="mt-4 flex shrink-0 flex-col gap-y-4 md:w-64 md:gap-y-4 lg:w-96"
                >
                    <location
                        :key="location.title"
                        :title="location.title"
                        button-text="Get directions"
                        :href="location.href"
                        :lines="location.lines"
                        :subtitle="location.subtitle"
                        v-for="location in locations"
                    ></location>
                </div>
                <div
                    class="relative flex grow items-center bg-white py-24 md:p-8"
                >
                    <DividerPixelsCorner
                        class="absolute left-0 top-0 w-full"
                    ></DividerPixelsCorner>
                    <Image alt="map" class="w-full" :src="MapLocations" />
                    <DividerPixelsBottom
                        class="absolute bottom-0 right-0 w-full"
                    ></DividerPixelsBottom>
                </div>
            </div>
        </inner-constraint>
    </section>
</template>
<script setup>
import Heading4 from '@/Components/texts/Heading4.vue'
import InnerConstraint from '@/Components/InnerConstraint.vue'
import Heading3 from '@/Components/texts/Heading3.vue'
import Location from '@/Components/locations/location.vue'
import MapLocations from '~/images/map/maplocations_without_times.webp'
import Float from '@/Components/animation/Float.vue'
import Image from '@/Components/images/Image.vue'
import Clouds from '~/images/3d-assets/clouds.webp'
import DividerPixelsBottom from '@/Components/dividers/DividerPixelsBottom.vue'
import DividerPixelsCorner from '@/Components/dividers/DividerPixelsCorner.vue'
import ScrollAnimator from '@/Components/animation/ScrollAnimator.vue'

const locations = [
    {
        title: 'Veste Wonen',
        subtitle: 'Housing association',
        lines: [
            'Monday - Thursday',
            '09:00 - 15:30',
            'Friday',
            '09:00 - 11:30',
        ],
        href: 'https://maps.app.goo.gl/mvs6w5BunXv1hXGK8',
    },
    {
        title: 'Subway',
        subtitle: 'Inside Bastille building',
        lines: [
            'Monday - Friday',
            '10:00 - 19:00',
            'Saturday - Sunday',
            '10:00 - 15:00',
        ],
        href: 'https://maps.app.goo.gl/15Cov2iwifn8TvuC8',
    },
    {
        title: 'U-Park hotel',
        lines: ['Monday - Sunday', '24h'],
        href: 'https://maps.app.goo.gl/zGE5aoam5uvHtEEb6',
    },
    {
      title: 'Stress',
      subtitle: 'Ravelijn',
      lines: ['Monday - Friday', '09:00 - 18:00'],
      href: 'https://maps.app.goo.gl/GVAzkDVCbjqRudcg6',
    },
  {
    title: 'Paradoks',
    subtitle: 'Techmed Centre',
    lines: ['Monday - Friday', '08:45 - 17:00'],
    href: 'https://maps.app.goo.gl/dn38fkuNRG6DkhCb6',
  },

  {
    title: 'Newton',
    subtitle: 'Horst',
    lines: ['Monday - Friday', '08:30 - 17:00'],
    href: 'https://maps.app.goo.gl/EnuvyyhUJJWPAKYR7',
  },
  {
    title: 'DesignLab',
    subtitle: 'The Gallery',
    lines: ['Monday - Friday', '08:30 - 20:00'],
    href: 'https://maps.app.goo.gl/QqxJhqPDnTT9LDyo6',
  },
  {
    title: 'Contact Centre',
    lines: ['Monday - Friday', '12:30 - 16:00'],
    href: 'https://maps.app.goo.gl/WkkqLrnPFkkSDTtn8',
  },
]
</script>
