<template>
    <section class="bg-white py-28 md:py-36" id="how-it-works-block">
        <inner-constraint v-animate-in>
            <heading4 class="mb-6 text-center">How it works</heading4>
            <heading3 class="text-center"
                >Rent easily with these 3 steps</heading3
            >

            <div
                v-animate-in
                class="mt-16 flex flex-col items-center gap-x-4 gap-y-16 md:mt-28 md:flex-row md:flex-nowrap md:items-start md:justify-between"
            >
                <template :key="step.title" v-for="(step, index) in steps">
                    <HowItWorksStep
                        class="shrink-0"
                        :icon="step.icon"
                        :title="step.title"
                        :subtitle="step.subtitle"
                    ></HowItWorksStep>
                    <img
                        class="shrink-1 mt-12 hidden h-1 w-full object-cover object-center md:block"
                        alt="divider"
                        v-if="index < steps.length - 1"
                        :src="Line"
                    />
                </template>
            </div>
        </inner-constraint>
    </section>
</template>
<script setup>
import Heading3 from '@/Components/texts/Heading3.vue'
import Heading4 from '@/Components/texts/Heading4.vue'
import HowItWorksStep from '@/Components/steps/HowItWorksStep.vue'
import InnerConstraint from '@/Components/InnerConstraint.vue'
import Line from '~/images/lines/dotted-line.svg'
import LocationIcon from '@/Components/images/icons/LocationIcon.vue'
import QrCodeIcon from '@/Components/images/icons/QrScanIcon.vue'
import UmbrellaIcon from '@/Components/images/icons/UmbrellaIcon.vue'

const steps = [
    {
        icon: LocationIcon,
        title: 'Choose location',
        subtitle: 'Go to the nearest participating location',
    },
    {
        icon: QrCodeIcon,
        title: 'Use the QR code',
        subtitle:
            'There will be QR codes at the desk, you need to scan the code to rent',
    },
    {
        icon: UmbrellaIcon,
        title: 'Borrow the umbrella',
        subtitle: 'You can use the umbrella for free for 1 day',
    },
]
</script>
