<template>
    <h2 class="font-BwGRadual text-4xl font-medium md:text-6xl">
        <slot></slot>
    </h2>
</template>
<style scoped>
:deep(span) {
    @apply text-plu-blue;
}
</style>
