<template>
    <svg
        width="31"
        height="32"
        viewBox="0 0 31 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.62 13.847C4.62 7.95909 9.54016 3.2002 15.4915 3.2002C21.4598 3.2002 26.38 7.95909 26.38 13.847C26.38 16.8139 25.301 19.5683 23.525 21.903C21.5657 24.4783 19.1508 26.7221 16.4325 28.4833C15.8104 28.8903 15.249 28.921 14.5661 28.4833C11.8325 26.7221 9.41755 24.4783 7.47504 21.903C5.69773 19.5683 4.62 16.8139 4.62 13.847ZM11.9086 14.1785C11.9086 16.1509 13.5182 17.7022 15.4915 17.7022C17.4663 17.7022 19.0914 16.1509 19.0914 14.1785C19.0914 12.2214 17.4663 10.5945 15.4915 10.5945C13.5182 10.5945 11.9086 12.2214 11.9086 14.1785Z"
            fill="white"
        />
    </svg>
</template>
