<template>
    <form
        class="flex flex-col gap-y-6 text-plu-black"
        @submit.prevent="submitForm"
    >
        <div class="">
            <InputLabel class="mb-1 text-plu-black" for="name">Name</InputLabel>
            <text-input
                v-model="form.name"
                type="text"
                id="name"
                placeholder="Name"
            ></text-input>
            <FormError v-if="form.errors.name">{{
                form.errors.name
            }}</FormError>
        </div>
        <div>
            <InputLabel class="mb-1 text-plu-black" for="email"
                >E-mail address</InputLabel
            >
            <text-input
                v-model="form.email"
                id="email"
                placeholder="E-mail address"
                type="email"
            ></text-input>
            <FormError v-if="form.errors.email">{{
                form.errors.email
            }}</FormError>
        </div>
        <div>
            <InputLabel class="mb-1 text-plu-black" for="phone">
                Phone Number
                <span class="float-right text-plu-text-gray-light"
                    >Optional</span
                ></InputLabel
            >
            <text-input
                v-model="form.phone"
                id="phone"
                placeholder="Phone number"
                type="tel"
            ></text-input>
            <FormError v-if="form.errors.phone">{{
                form.errors.phone
            }}</FormError>
            <FormError v-if="form.errors.email">{{
                form.errors.phone
            }}</FormError>
        </div>

        <div class="text-center">
            <FormSuccess v-if="form.wasSuccessful">
                Thank-you! Your message is received successfully.
            </FormSuccess>
            <PrimaryButton
                v-else
                :disabled="form.processing"
                type="submit"
                class="mt-8"
                >Send</PrimaryButton
            >
        </div>
    </form>
</template>
<script setup>
import PrimaryButton from '@/Components/buttons/PrimaryButton.vue'
import TextInput from '@/Components/inputs/TextInput.vue'
import InputLabel from '@/Components/inputs/InputLabel.vue'
import { useForm } from '@inertiajs/vue3'
import FormError from '@/Components/texts/FormError.vue'
import FormSuccess from '@/Components/texts/FormSuccess.vue'

const form = useForm({
    name: '',
    email: '',
    phone: '',
})

function submitForm() {
    form.post('/message', {
        preserveScroll: true,
        onSuccess: () => {
            form.reset()
        },
    })
}
</script>
