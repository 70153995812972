<template>
    <section class="bg-white py-20 md:py-36" id="faq-block">
        <InnerConstraint class="relative" v-animate-in>
            <Float>
                <Image
                    alt="Thunder cloud"
                    class="absolute -top-48 right-0 w-48 md:-top-64 md:w-80"
                    :src="ThunderCloud"
                ></Image>
            </Float>
            <heading4 class="mb-2 md:mb-4">We've got you covered</heading4>
            <heading3 class="mb-16">Frequently asked questions</heading3>
            <faq-item
                v-for="(question, index) in faqQuestions"
                :key="question.question"
                :question="question.question"
                :answers="question.answers"
                :question-number="index + 1"
            ></faq-item>

            <div class="w-full border-b border-plu-black"></div>
        </InnerConstraint>
    </section>
</template>
<script setup>
import InnerConstraint from '@/Components/InnerConstraint.vue'
import Heading4 from '@/Components/texts/Heading4.vue'
import Heading3 from '@/Components/texts/Heading3.vue'
import FaqItem from '@/Components/faq/FaqItem.vue'
import Image from '@/Components/images/Image.vue'
import ThunderCloud from '~/images/3d-assets/thunder-cloud.webp'
import Float from '@/Components/animation/Float.vue'

const faqQuestions = [
    {
        question: 'How can I find the participating locations?',
        answers: [
            "When searching for the participating locations, keep an eye out for our distinctive posters and flyers going around the UT campus showing the locations. Simply follow the map, and it will lead you straight to the correct location. You can also press the button: 'get directions' above.",
        ],
    },
    {
        question: 'What should I do if something happens to the umbrella?',
        answers: [
            "Don't worry! Please get in touch with us through our socials or email us at: info@plu-umbrellas.com",
        ],
    },
    {
        question: 'Performance and service?',
        answers: [
            'When it comes to performance and service, rest assured, we strive for excellence. We want to give the best experience to the user as possible. If you feel we can improve on something or you have some ideas, please contact us through our socials or send us an e-mail: info@plu-umbrellas.com',
        ],
    },
    {
        question: 'What if I miss the deadline of one day?',
        answers: [
            'You can contact us on our socials or by e-mail address: info@plu-umbrellas.com',
        ],
    },
    {
        question: 'What about future pilots?',
        answers: [
            'Do you want to stay in the know about any future pilots and/or developments, you can subscribe to our newsletter or follow our socials! For business-related questions, or if you as a company would be interested in participating, you can contact us via info@plu-umbrellas.com.',
        ],
    },
]
</script>
