<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="18"
            cy="18"
            r="17"
            transform="matrix(1 0 0 -1 0 36)"
            stroke="#66B3BD"
            stroke-width="2"
        />
        <path
            d="M5.27209 18L18 30.7279L30.7279 18"
            stroke="#66B3BD"
            stroke-width="2"
        />
        <path d="M18 30.728L18 5.6762" stroke="#66B3BD" stroke-width="2" />
    </svg>
</template>
